<template>
    <div class="wrap">
        <!-- <Floating :currentMusic="this.$store.state.musicList[this.$store.state.currentMusicIdx]" :isPlay="this.$store.state.isPlay === '' ? false : this.$store.state.isPlay" /> -->
        <Play
            :spaceId="spaceInfo.spaceId"
            :spaceNm="spaceInfo.spaceNm"
            :findChannel="'N'"
            v-if="ShowPlay"
            @closePlay="ShowPlay = false"
        />

        <!-- <Play :spaceId="spaceInfo.spaceId" :spaceNm="spaceInfo.spaceNm" :findChannel="'N'" v-if="ShowPlay"
      @closePlay="ShowPlay = false" /> -->
        <div class="space_pc">
            <section class="main space4_4 space4_5">
                <!-- 채널 탐색 중 -->
                <div class="main_box" v-if="lodingStatus && lodingStatus2 === false">
                    <h3>
                        채널을 탐색 중입니다.<br />
                        잠시만 기다려주세요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                        <!-- <button class="opacity">다음</button> -->
                    </div>
                </div>
                <!-- 새로운 채널 생성 중 -->
                <div class="main_box" v-else-if="lodingStatus2 && lodingStatus === false">
                    <h3>
                        동일한 조건의 채널이 없습니다.<br />
                        새로운 채널을 생성해드릴게요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                        <button class="opacity">다음</button>
                    </div>
                </div>
                <!-- 로딩 완료 -->
                <div class="main_box" v-else>
                    <h3 v-if="spaceInfo.sector != null">
                        <span>{{ spaceInfo.mood }}한</span> 분위기의<br />
                        <span v-html="spaceInfo.spaceNm" /> 에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ spaceInfo.carMood }}</span> 분위기의<br />
                        <span v-for="(item, index) in spaceInfo.carPurpose" :key="index">{{ item }}</span> 차량에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt="" /></div>
                            <img src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                        <button @click="linkPayment()">다음</button>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 채널 탐색 중 -->
        <div class="space_mo h_100" v-if="lodingStatus && lodingStatus2 === false">
            <section class="main space4_4">
                <div class="main_box">
                    <h3>
                        채널을 탐색 중입니다.<br />
                        잠시만 기다려주세요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <!-- <button style="text-align:center;" class="opacity3">다음</button> -->
            </div>
        </div>
        <!-- 모바일 채널 생성 중 -->
        <div class="space_mo h_100" v-else-if="lodingStatus2 && lodingStatus === false">
            <section class="main space4_4">
                <div class="main_box">
                    <h3>
                        동일한 조건의 채널이 없습니다.<br />
                        새로운 채널을 생성해드릴게요.
                    </h3>
                    <div class="loading_ing">
                        <div class="wrapper">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                            <div class="shadow"></div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button style="text-align: center" class="opacity3">다음</button>
            </div>
        </div>
        <!-- 모바일 로딩 완료 -->
        <div class="space_mo h_100" v-else>
            <section class="main space4_4 space4_5">
                <div class="main_box">
                    <h3 v-if="spaceInfo.sector != null">
                        <span>{{ spaceInfo.mood }}한</span> 분위기의<br />
                        <span v-html="spaceInfo.spaceNm" /> 에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <h3 v-else>
                        <span>{{ spaceInfo.carMood }}</span> 분위기의<br />
                        <span v-for="(item, index) in spaceInfo.carPurpose" :key="index">{{ item }}</span> 차량에<br />
                        어울리는 음악 선곡이 완료되었습니다.
                    </h3>
                    <div class="loading_ing">
                        <div class="meet_boll">
                            <div><img src="/media/img/space_4_check.svg" alt="" /></div>
                            <img src="/media/img/space_loding_img2.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <div class="form_bottom">
                <button style="text-align: center" @click="linkPayment()">다음</button>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import router from '@/router';
import Play from '@/components/modal/Space/PaymentFinished.vue';

export default defineComponent({
  name: 'FinishedCuration',
  data () {
    return {
      spaceInfo: {},
      lodingStatus: true,
      lodingStatus2: false,
      ShowPlay: false
    };
  },
  components: {
    Play
  },
  methods: {
    async linkPayment () {
      // 결제페이지로 이동
      var spaceId = this.spaceInfo.spaceId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      if (this.spaceInfo.spaceType === 'Building') {
        axios
          .post('/api/profile/updateCarPayAt', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              this.$store.commit('setFindChannel', 'N');
              router.push({ name: 'MySpace' });
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        var userId = VueCookies.get('userId');

        // 카드 등록 여부 확인
        const card = await axios.post('/api/product/getMyCardInfo', { userId }, { headers }).catch(e => {
          console.log(e);
        });

        console.log(
          'searCh',
          card.data.resultCd === '0000' && card.data.result == null && this.$store.state.trialStatus
        );
        console.log('searCh', this.$store.state.spacePayAt);
        if (card.data.resultCd === '0000' && card.data.result == null && this.$store.state.trialStatus) {
          // 등록된 카득가 없을 경우 체험판으로
          const spacePayAt = 'T';
          const res = await axios
            .post('/api/profile/updateTrialSpaceInfo', { userId, spaceId, spacePayAt, isNew: 'Y' })
            .catch(e => {
              console.log(e);
            });
          if (res.data.resultCd === '0000') {
            if (res.data.result === 0) {
              router.push({ name: 'SpacePayment' });
              return false;
            }

            VueCookies.set('spaceId', spaceId);
            router.push({ name: 'Player' });
          }
        } else {
          // 등록된 카드가 있을 경우 결제 페이지로
          router.push({ name: 'SpacePayment', params: { findChannel: 'N' } });
        }
        // router.push({ name: "SpacePayment", params: { findChannel: 'N' } });
      }
    },
    async getSpaceInfo () {
      var spaceId = localStorage.getItem('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/profile/getCurationInfo', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.spaceInfo = res.data.result;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    loding () {
      this.lodingStatus = false;
      this.lodingStatus2 = true;
    },
    loding2 () {
      this.lodingStatus2 = false;
    }
  },
  mounted () {
    this.getSpaceInfo();
    setTimeout(this.loding, 2000);
    setTimeout(this.loding2, 4000);
  }
});
</script>
<style scoped>
/* .space4_4 .loading_ing>div img:nth-child(1) {
    left: 0;
    top: 0;
  } */
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
